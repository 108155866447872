import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import divider from 'assets/images/Line_topic.png';

import Group24 from '../../assets/images/topics/group-24.inline.svg';
import banner from '../../assets/images/topics/Innovation.jpg';
import Group27 from '../../assets/images/topics/group-27.inline.svg';
import Asset from '../../assets/images/topics/Asset.inline.svg';
import CTATopic from '../../components/CTATopic';

const Assets = () => {
  return (
    <Layout>
      <Helmet>
        <title>EY - The enlightened session</title>
      </Helmet>
      <div className='topicTemplate'>
        <Container>
          <div className='topicIntro'>
            <div className='banner'>
              <img src={banner} alt='assets Banner' />
            </div>
            <div className='text'>
              <Row>
                <Col>
                  <h1>Assets</h1>

                  <div className='introText'>
                    <p>
                      Our traditional asset-heavy energy network is changing
                      shape. As energy becomes more distributed and more
                      intermittent, the network becomes more complex to manage.
                      The need to capitalize rapidly on new opportunities,
                      fulfil regulatory obligations, keep investors informed and
                      meet stakeholder expectations, including workforce health
                      and safety, demands an enhanced asset-management strategy.
                    </p>
                    <p>
                      Companies that succeed in managing and operating their
                      assets most effectively, and compete with the influx of
                      innovative and digitally enabled industry disruptors, take
                      a user-centric approach to designing and implementing
                      their systems. They focus on solutions that are
                      sustainable and adaptable to changing technologies in
                      order to manage the complexities of the new energy era.
                    </p>
                  </div>
                </Col>
                <Col />
              </Row>
            </div>
          </div>
          <div className='divider'>
            <img src={divider} alt='divider' />
          </div>
          <div>
            <div className='followSoon'>
              <h2>
                - Keynote speakers will <span>follow soon</span> -
              </h2>
            </div>
          </div>
          <div className='topicExpect'>
            <h1>
              What you <span>can expect?</span>
            </h1>
            <div>
              <p>
                Energy companies will succeed in responding to energy industry
                disruption, competition, regulation and risk by extracting
                greater value from existing and new infrastructure. This
                enlightened session is all about how you can make your assets
                work better for you.
              </p>
              <p>
                The session offers you a refreshingly clear perspective on the
                tumult of challenges and opportunities facing the energy
                industry today. Our keynote speakers will introduce you to
                real-life examples of energy companies that are tackling asset
                management challenges effectively. An interactive session will
                focus specifically on your organization, the asset management
                challenges it encounters and how to tackle them.
              </p>
            </div>
            <div className='expectWrapper'>
              <div className='expectItem'>
                <Asset className='asset' />
                <h6>The new age</h6>
                <p>
                  The development of new technologies is already improving
                  day-to-day asset maintenance and planning. Find out how
                  companies can integrate reliability and integrity into their
                  operating strategies and early asset design.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group24 />
                <h6>Keynote session</h6>
                <p>
                  Our speakers focus on discrete and high-impact test cases that
                  demonstrate the value of effective asset management. Discover
                  how to connect data-centric asset performance with a
                  continuous improvement mentality. Understand how to build an
                  organization that operates its assets most efficiently in the
                  future.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group27 />
                <h6>Interactive discussion</h6>
                <p>
                  We invite you to a deep-dive session to future-proof your
                  asset management operations. Learn what systems and solutions
                  you need to underpin your asset life cycle, from acquisition
                  to disposal.
                </p>
                <p>
                  <em>Limited audience</em>
                </p>
              </div>
            </div>
          </div>
          <CTATopic
            title={'Asset'}
            description={
              'Join in this unique experience. Sign up for this exclusive enlightened session and learn to manage and operate your energy assets more effectively.'
            }
          />
        </Container>
      </div>
    </Layout>
  );
};
export default Assets;
